/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .popover-button {
    position: fixed;
    bottom: 60px; /* Adjust as needed */
    right: 22px; /* Adjust as needed */
    z-index: 1000; /* Ensure it stays on top */
  }

  .primary-font {
    font-family: "DM Serif Display", serif;
    font-weight: 400;
  }

  .secondary-font {
    @apply font-secondary;
  }

  .dm-serif-display-regular {
    font-family: "DM Serif Display", serif;
    font-weight: 400;
    font-style: normal;
  }

  .dm-serif-display-regular-italic {
    font-family: "DM Serif Display", serif;
    font-weight: 400;
    font-style: italic;
  }

  .flex-col-left-top {
    @apply flex flex-col justify-start items-start gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-col-left-center {
    @apply flex flex-col justify-center items-start gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-col-left-bottom {
    @apply flex flex-col justify-end items-start gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-col-center-top {
    @apply flex flex-col justify-start items-center gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-col-center-center {
    @apply flex flex-col justify-center items-center gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-col-center-bottom {
    @apply flex flex-col justify-end items-center gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-col-right-top {
    @apply flex flex-col justify-start items-end gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-col-right-center {
    @apply flex flex-col justify-center items-end gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-col-right-bottom {
    @apply flex flex-col justify-end items-end gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-row-left-top {
    @apply flex flex-row justify-start items-start gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-row-left-center {
    @apply flex flex-row justify-start items-center gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-row-left-bottom {
    @apply flex flex-row justify-start items-end gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-row-center-top {
    @apply flex flex-row justify-center items-start gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-row-center-center {
    @apply flex flex-row justify-center items-center gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-row-center-bottom {
    @apply flex flex-row justify-center items-end gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-row-right-top {
    @apply flex flex-row justify-end items-start gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-row-right-center {
    @apply flex flex-row justify-end items-center gap-[0.05rem] sm:gap-[0.5rem];
  }

  .flex-row-right-bottom {
    @apply flex flex-row justify-end items-end gap-[0.05rem] sm:gap-[0.5rem];
  }

  .gap-lg {
    @apply gap-[2rem] sm:gap-[4rem];
  }

  .gap-md {
    @apply gap-[1rem] sm:gap-[2rem];
  }

  .gap {
    @apply gap-[0.5rem] sm:gap-[1rem];
  }

  .gap-sm {
    @apply gap-[0.125rem] sm:gap-[0.25rem];
  }

  .gap-xs {
    @apply gap-[0.05rem] sm:gap-[0.1rem];
  }

  .full {
    @apply w-[100%];
  }

  .one-half {
    @apply w-[50%];
  }

  .one-third {
    @apply w-[33%];
  }

  .two-thirds {
    @apply w-[66%];
  }

  .padding-lg {
    @apply p-[2rem] sm:p-[4rem];
  }

  .padding {
    @apply p-[0.5rem] sm:p-[1rem];
  }

  .padding-sm {
    @apply p-[0.375rem] sm:p-[0.75rem];
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
    color: rgb(0, 0, 0); /* Set the default text color to red */
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  small,
  a {
    @apply primary-font;
    color: inherit;
  }

  span,
  p,
  label,
  input,
  textarea,
  button {
    @apply secondary-font;
  }
}

.page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  min-height: 100vh;
}

.logo-container {
  display: flex;
  justify-content: center;
}

.logo {
  margin: auto;
  width: 50%;
}

/* Header */
.header-link-active {
  width: 30%;
  border-radius: 5px;
  padding: auto;
  text-align: center;
  font-weight: 600; /* 600 is the value for semi-bold */
}

.header-link-inactive {
  width: 30%;
  border-radius: 5px;
  padding: auto;
  text-align: center;
  font-weight: 400;
}

/* Loader */
.spinner {
  animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }

  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

@keyframes colors {
  0% {
    stroke: #000;
  }

  100% {
    stroke: #000;
  }
}

/* Ensure the root container takes up at least the full viewport height */
html,
body,
#root {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
}

/* Ensure the main content area expands to fill available space */
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Sticky footer styles */
.sticky-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: white; /* Adjust as needed */
  z-index: 10; /* Ensure it stays above other content if needed */
}
